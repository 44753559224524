// scss
@import './standard';

// bundle
@import './grid';
@import './buttons';

.dev {
  background: #dc3545;
  color: white;
  padding: 0.25em 0.4em;
  font-size: 75%;
  text-align: center;
  white-space: nowrap;
  border-radius: 0.25rem;
  font-weight: 700;
}

.chart {
  padding: 10px;
}

///// Shadows from bootstrap
.shadow-sm {
  box-shadow: 0 0.125rem 0.25rem rgba(0, 0, 0, 0.075) !important;
}

.shadow {
  box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15) !important;
}

.shadow-lg {
  box-shadow: 0 1rem 3rem rgba(0, 0, 0, 0.175) !important;
}

.shadow-none {
  box-shadow: none !important;
}

.page-heading {
  font-size: 1.1rem;
  margin-bottom: 2rem;
}

////// Forms
.form-group {
  margin-bottom: 1rem;

  label {
    font-size: 1rem;
    font-weight: 600;
    color: var(--label-color);
    margin-bottom: 5px;
  }

  // p {
  //   //   font-size: 0.85rem;
  //   margin-bottom: 0;

  //   &:last-of-type {
  //     margin-bottom: 0.7rem;
  //   }
  // }
}

.input-group {
  display: flex;
  //align-items: center;
  width: 100%;

  label {
    min-width: 40%; // try to align a bunch of items to the right
    margin-right: 1.5rem;
    color: var(--label-color);
  }
}

////// Tabs
.tab-body {
  padding: 20px;
  border: 1px solid rgba(0, 0, 0, 0.125);
}

//prevent pages from growing with content
//used for list pages to help the table scroll nicely
.static-height {
  height: calc(100vh - 210px);
}

.form-heading {
  color: #1a8fff;
  font-size: 1.2rem;
  margin-bottom: 5px;
}

hr {
  margin-top: 2rem !important;
  border-top: 1px solid lightgray !important;
  margin-bottom: 2rem !important;
}

.filter-item {
  margin-bottom: 1rem;

  label {
    font-weight: 600;
  }
}

// sass variables to generate css vars
@mixin colorToCssVar($name, $color, $dark: 5%, $darker: 10%, $darkest: 35%, $light: 5%, $lighter: 10%, $lightest: 37%) {
  --#{$name}: #{$color};
  --#{$name}-dark: #{darken($color, $dark)};
  --#{$name}-darker: #{darken($color, $darker)};
  --#{$name}-darkest: #{darken($color, $darkest)};
  --#{$name}-light: #{lighten($color, $light)};
  --#{$name}-lighter: #{lighten($color, $lighter)};
  --#{$name}-lightest: #{lighten($color, $lightest)};
  --#{$name}-rgb: #{hexToRGBString($color)};
}

// sass function to write out the rgb text from a hex color
@function hexToRGBString($hexColor) {
  @return '#{red($hexColor)},#{green($hexColor)},#{blue($hexColor)}';
}

:root {
  // system colors
  @include colorToCssVar('color-success', #0abb87, $lightest: 46%);
  @include colorToCssVar('color-complete', #48b0f7, $lightest: 34%);
  @include colorToCssVar('color-warning', #ffce00, $lightest: 43%);
  @include colorToCssVar('color-danger', #f04141, $lightest: 34%);
  @include colorToCssVar('color-info', #3b4752, $lightest: 45%);
  @include colorToCssVar('color-background', #312c57);

  @include colorToCssVar('color-primary', #5d78ff);
  @include colorToCssVar('color-secondary', #d7784a);

  // light & dark
  @include colorToCssVar('color-light', #fff);
  @include colorToCssVar('color-dark', #282a3c, $lightest: 65%);

  // text
  --default-text-color: #626262;
  --label-color: #363948;

  --background-color: #f1f5f8;
}

@import 'button_base';
@import 'button_group';

// override default border-radius
.btn {
  border-radius: 5px;
  letter-spacing: 0.5px;
}

// create a range of button options
@mixin create-button($color, $value) {
  .#{$color}-button {
    font-weight: 500;
    background-color: var(#{$value});
    color: white;

    &:hover {
      color: white;
      background-color: var(#{$value}-dark);
    }

    &:disabled {
      background-color: var(#{$value});
    }
  }

  .#{$color}-button-outline {
    font-weight: 500;
    background-color: transparent;
    color: var(#{$value});
    border: 1px solid var(#{$value});

    &:hover {
      color: white;
      background-color: var(#{$value});
    }

    &:disabled {
      color: var(#{$value});
      background-color: transparent;
    }
  }

  .#{$color}-button-hover {
    font-weight: 500;
    background-color: transparent;
    color: var(--default-text-color);

    &:hover {
      color: var(#{$value});
      border: 1px solid var(#{$value});
    }

    &:disabled {
      background-color: transparent;
    }
  }

  .#{$color}-button-faded {
    font-weight: 500;
    background-color: var(#{$value}-lightest);
    color: var(#{$value});

    &:hover {
      background-color: var(#{$value});
      color: white;
    }

    &:disabled {
      color: var(#{$value});
      background-color: var(#{$value}-lightest);
    }
  }

  .#{$color}-button-link {
    color: var(#{$value});
    text-shadow: none;

    &:hover {
      color: white;
      color: var(#{$value}-darker);
      text-shadow: 0 0 0.65px rgba(0, 0, 0, 0.12), 0 0 0.65px rgba(0, 0, 0, 0.12); // https://www.sitepoint.com/quick-tip-fixing-font-weight-problem-hover-states/
    }

    &:disabled {
      color: var(#{$value}-darkest);
    }
  }
}

// btn primary-button
// btn primary-button-outline
// btn primary-button-hover
// btn primary-button-faded
// btn primary-button-link
@include create-button('primary', '--color-primary');

// btn secondary-button
// btn secondary-button-outline
// btn secondary-button-hover
// btn secondary-button-faded
// btn secondary-button-link
@include create-button('secondary', '--color-secondary');

// btn success-button
// btn success-button-outline
// btn success-button-hover
// btn success-button-faded
// btn success-button-link
@include create-button('success', '--color-success');

// btn complete-button
// btn complete-button-outline
// btn complete-button-hover
// btn complete-button-faded
// btn complete-button-link
@include create-button('complete', '--color-complete');

// btn warning-button
// btn warning-button-outline
// btn warning-button-hover
// btn warning-button-faded
// btn warning-button-link
@include create-button('warning', '--color-warning');

// btn danger-button
// btn danger-button-outline
// btn danger-button-hover
// btn danger-button-faded
// btn danger-button-link
@include create-button('danger', '--color-danger');

// btn info-button
// btn info-button-outline
// btn info-button-hover
// btn info-button-faded
// btn info-button-link
@include create-button('info', '--color-info');

// btn light-button
// btn light-button-outline
// btn light-button-hover
// btn light-button-faded
// btn light-button-link
@include create-button('light', '--color-light');

// overrides for light style
.light-button,
.light-button-faded,
.light-button-hover,
.light-button-outline {
  color: var(--default-text-color);

  &:hover {
    color: var(--default-text-color);
  }

  &:disabled {
    color: var(--default-text-color);
    border: 1px solid var(--color-light-dark);
  }
}

.light-button-hover {
  &:hover {
    background-color: var(--color-light-dark);
  }

  &:disabled {
    background-color: transparent;
  }
}

.light-button-outline {
  border: 1px solid var(--color-light-dark);

  &:hover {
    background-color: var(--color-light-dark);
  }

  &:disabled {
    background-color: transparent;
    border: 1px solid var(--color-light-dark);
  }
}

.light-button-link {
  color: var(--default-text-color);

  &:hover {
    color: var(--default-text-color);
  }
}

// btn dark-button
// btn dark-button-outline
// btn dark-button-hover
// btn dark-button-faded
// btn dark-button-link
@include create-button('dark', '--color-dark');

.btn-elevated {
  box-shadow: 0 9px 16px 0 rgba(153, 153, 153, 0.25) !important;
}

.btn-pill {
  border-radius: 2rem !important;
}

//
// FROM BOOTSTRAP
//
// Base styles
//

.btn {
  display: inline-block;
  font-weight: 400;
  line-height: 1.5;
  color: #212529;
  text-align: center;
  vertical-align: middle;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  background-color: transparent;
  border: 1px solid transparent;
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  border-radius: 0.25rem;
  transition: all 0.15s ease-in-out;
  box-shadow: none;
}
@media (prefers-reduced-motion: reduce) {
  .btn {
    transition: none;
  }
}

.btn:hover {
  color: #212529;
  text-decoration: none;
}

.btn.focus,
.btn:focus {
  outline: 0;
  // box-shadow: 0 0 0 .2rem rgba(13, 110, 253, 0.25);
}

.btn.disabled,
.btn:disabled {
  opacity: 0.65;
  cursor: default;
}

a.btn.disabled,
fieldset:disabled a.btn {
  pointer-events: none;
}

//
// Button Sizes
//

.btn-group-lg > .btn,
.btn-lg {
  padding: 0.5rem 1rem;
  font-size: 1.25rem;
  border-radius: 0.3rem;
}

.btn-group-sm > .btn,
.btn-sm {
  padding: 0.25rem 0.5rem;
  font-size: 0.875rem;
  border-radius: 0.2rem;
}

//
// Block button
//

.btn-block {
  display: block;
  width: 100%;
}

// .btn-block + .btn-block {
//   margin-top: .5rem;
// }
